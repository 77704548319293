const STRAPI_URL = process.env.REACT_APP_STRAPI_URL;

class FaqService {
  constructor(target) {
    this.target = target;
  }

  async get() {
    try {
      const response = await fetch(STRAPI_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: this.query() }),
      });
      return response.json();
    } catch (error) {
      throw error;
    }
  }
  query() {
    return `query Faqs {
      faqs(filters: { service: { name: { eq: "${this.target}" } } }) {
        faq {
          category
          faqDetail {
            id
            title
            body
          }
        }
        pageAttribute {
          metaDescription
          pageTitle
          siteTitle
        }
      }
    }`;
  }
}

export default FaqService;
